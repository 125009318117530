import cx from "classnames";
import { ReactElement, useState } from "react";

import styles from "./AiSummaryStarRating.module.css";
import { Disclaimer } from "../../Element/Typography";
import { starRatingIcon } from "./AiSummaryIcon";

type AiSummaryStarRatingProps = {
  submitStarRating?: (rating: number) => void;
};

export default function AiSummaryStarRating({
  submitStarRating,
}: AiSummaryStarRatingProps): ReactElement {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const ratings = [1, 2, 3, 4, 5];

  return (
    <div className={styles.starRatingContainer}>
      <div className={styles.starRating}>
        {ratings.map((_, i) => {
          const currentRating = i + 1;

          return (
            <label className={styles.ratingLabel} key={i}>
              <input
                type="radio"
                name="rating"
                value={currentRating}
                onChange={() => {
                  setRating(currentRating);
                  //Send event to GTM
                  submitStarRating && submitStarRating(currentRating);
                }}
              />

              <span
                className={cx(
                  styles.starIconLabel,
                  currentRating <= (hover || rating) &&
                    styles.starIconLabelFilled,
                )}
                onMouseEnter={() => {
                  setHover(currentRating);
                }}
                onMouseLeave={() => {
                  setHover(0);
                }}
              >
                {starRatingIcon}
              </span>
            </label>
          );
        })}
      </div>
      {rating !== 0 && (
        <div className={styles.feedback}>
          <Disclaimer>Feedback Submitted</Disclaimer>
        </div>
      )}
    </div>
  );
}
