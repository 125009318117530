import cx from "classnames";
import { ComponentProps, ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";

import styles from "./AiSummaryLightbox.module.css";
import AiSummaryStarRating from "./AiSummaryStarRating";
import CloseIcon from "../../Element/CloseIcon/CloseIcon";
import { Body, Disclaimer } from "../../Element/Typography";
import { SubHeading2 } from "../../Element/Typography/Heading/Heading";
import * as Ads from "../../Utility/Ads/Ads";

type AiSummaryLightboxProps = {
  isOpen: boolean;
  toggle: () => void;
  data: {
    title: string;
    path: string;
    items?: string[];
  };
  gaEventTracker?: () => void;
  submitStarRating?: ComponentProps<
    typeof AiSummaryStarRating
  >["submitStarRating"];
};

export default function AiSummaryLightbox({
  isOpen,
  toggle,
  data,
  gaEventTracker,
  submitStarRating,
}: AiSummaryLightboxProps): ReactNode {
  useEffect(() => {
    if (!isOpen) return;

    gaEventTracker && gaEventTracker();
  }, [isOpen, gaEventTracker]);

  return createPortal(
    <div className={cx(styles.lightbox, isOpen && styles.lightboxOpen)}>
      <div className={styles.lightboxContainer}>
        <div className={styles.lightboxContent}>
          <div className={styles.lightboxHeader}>
            <button onClick={toggle} className={styles.closeButton}>
              <CloseIcon size={20} />
            </button>
          </div>
          <div className={styles.mainContent}>
            <div className={styles.scrollableContent}>
              <div className={styles.summary}>
                <div className={styles.disclaimer}>
                  <Disclaimer Element="div">
                    This summary was generated using AI who is still learning!
                  </Disclaimer>
                </div>

                {data.title && <SubHeading2>{data.title}</SubHeading2>}

                <ul className={styles.summaryList}>
                  {!!data.items &&
                    data.items.map((item, index) => {
                      return (
                        <li key={index} className={styles.listItem}>
                          {item}
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className={styles.rating}>
                <Body Element="p">Was this Summary helpful?</Body>
                <AiSummaryStarRating submitStarRating={submitStarRating} />
              </div>
            </div>
          </div>
          <div className={styles.advertisement}>
            <Ads.AdsAiSummary />
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
}
