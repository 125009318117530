import { configGAM, configGTM, configSite } from "@pub/config";
import { Gam, GAMProps } from "@sphtech/dmg-design-system";
import { DISABLE_ADS } from "@src/app/constants";
import { isSponsoredStory } from "@src/app/utils/sponsored";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import gamSrc from "../../js/gam.js?url";
import { StoryData } from "../types/ServerCustomContext";
import headCssString from "./AdSettings.head.css?raw";

const sphmSiteName = configSite.name;
const sphmSiteAddress = configSite.name;
const sphmContainerId = configGTM.container;
const sphmAdAccount = configGAM.account;

type Props = Omit<GAMProps, "StoryData"> & {
  StoryData?: Partial<StoryData>;
};

export const NoAds = () => {
  useEffect(() => {
    document.body.classList.add("noads");
    return () => {
      document.body.classList.remove("noads");
    };
  }, []);
  return null;
};

export default DISABLE_ADS ? NoAds : AdSettings;

// a self executing function injected into the head
const overlaySEF = function () {
  const htmlClassList = document.documentElement.classList;
  if (
    screen.width >= 1000 &&
    (!document.cookie.includes("prestitialDisplayed=yes") ||
      window.location.search.includes("dfp_preview"))
  ) {
    htmlClassList.add("gotoverlay");
    setTimeout(function () {
      if (!htmlClassList.contains("gotprestitial")) {
        htmlClassList.remove("gotoverlay");
      }
    }, 4e3);
  }
};

function AdSettings({
  sectionName,
  pageType,
  pageTitle,
  adUnits,
  StoryData,
  currentLocation,
}: Props) {
  useEffect(() => {
    if (window.gpt_grapeshots_promise) {
      window.gpt_grapeshots_promise
        .then(() => {
          window.SPHMGAM.cmd.push("start");
        })
        .catch(() => {
          window.SPHMGAM.cmd.push("start");
        });
    } else {
      window.SPHMGAM.cmd.push("start");
    }
  }, []);

  const { baseData } = Gam({
    sectionName,
    pageType,
    pageTitle,
    adUnits,
    StoryData: {
      id: StoryData?.id || "",
      published: StoryData?.publishDate || "",
      updated: StoryData?.updateDate || "",
      authors: StoryData?.authors || [],
      tags: StoryData?.tags || [],
      typeName: StoryData?.typeName || "",
      urlPath: StoryData?.path || "",
      sections: StoryData?.sections || [],
      sponsorship: StoryData?.sponsorship || {},
    },
    currentLocation,
    siteConfig: {
      siteName: sphmSiteName,
      siteAddress: sphmSiteAddress,
      gtmContainer: sphmContainerId,
      adAccount: sphmAdAccount,
    },
  });

  const sponsortag = isSponsoredStory(
    StoryData?.sponsorship,
    StoryData?.typeName,
  ) && <meta name="sponsored" content="true" />;

  return (
    <Helmet>
      {sponsortag}
      <script>{`${[
        "window.SPHMGAM=window.SPHMGAM||{cmd:[]};",
        "window.googletag=window.googletag||{cmd:[]};",
        "if(!googletag.slots){googletag.slots={};}",
        "window.loaded_dfp_tags=window.loaded_dfp_tags||'{}';", //stringified json
        "window.gptadslots=window.gptadslots||[];",
        `window.sphm_base_data=${JSON.stringify(baseData)};`,
      ].join("")}(${overlaySEF.toString()})()`}</script>
      <style type="text/css">{headCssString}</style>
      <script
        async
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
      />
      <link
        href="https://adtag.sphdigital.com/tag/smx/smx_prebid.js"
        rel="prefetch"
      />
      <script src="https://adtag.sphdigital.com/tag/smx/prebid.js" async />
      {/* ToDo: remove once code is ported into entry-ads.ts, ensure entry-ads.ts is uncommented in index.html */}
      <script src={gamSrc} async />
    </Helmet>
  );
}
