import { createContext, useContext } from "react";

export type DesignContextType = {
  anchorBlock?: {
    verticalLine?: string | string[];
    summaryItem?: string | string[];
  };
  authorProfile?: {
    verticalLine?: string | string[];
  };
  button?: {
    primary?: string | string[];
    secondary?: string | string[];
  };
  callBlock?: {
    verticalLine?: string | string[];
  };
  divider?: {
    secondary?: string | string[];
  };
  figureCaptions?: {
    imageCaption?: string | string[];
  };
  heading?: {
    headingBase?: string | string[];
    headingSplit?: string | string[];
  };
  homeContentBlockB?: {
    contentBlockContainer?: string | string[];
    contentBlockScroll?: string | string[];
  };
  magazineListing?: {
    border?: string | string[];
    divider?: string | string[];
    verticalLine?: string | string[];
  };
  navLinks?: {
    verticalLineHeader?: string | string[];
    verticalLineFooter?: string | string[];
  };
  relatedItem?: {
    verticalLine?: string | string[];
    firstItem?: string | string[];
  };
  stickyTitle?: {
    font?: string | string[];
  };
  textLink?: string | string[];
  videoGallery?: {
    verticalLine?: string | string[];
  };
  categoryLayout?: {
    sectionContainer?: string | string[];
  };
  navbar?: {
    primaryItems?: string | string[];
  };
  contentHubLayout?: {
    userSubmissionBox?: string | string[];
  };
};

const CustomClassContext = createContext<DesignContextType | null>(null);

const useCustomClassContext = () => useContext(CustomClassContext);

export { useCustomClassContext, CustomClassContext };
