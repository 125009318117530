import { configGAM } from "@pub/config";
import { GRAPESHOT_ENDPOINT } from "@src/app/constants";
import { Helmet } from "react-helmet-async";

export default AnalyticsGrapeshot;

function AnalyticsGrapeshot({
  grapeshots,
}: {
  grapeshots?: {
    channels: string[];
    fr: string;
  };
}) {
  if (configGAM.clientSideGrapeshot && GRAPESHOT_ENDPOINT) {
    return (
      <Helmet>
        <script type="text/javascript">
          {`(function loadGrapeshotSEF() {
  window.gpt_grapeshots_promise = fetch(
    "${GRAPESHOT_ENDPOINT}?url=" + encodeURIComponent(location.origin + location.pathname),
    {signal: "AbortSignal" in window && typeof AbortSignal.timeout === "function" ? AbortSignal.timeout(5000) : undefined}
  ).then((response) => response.json()).then((data) => {
    const dataObject = data && typeof data === "object" ? data : {};
    const channels = Array.isArray(dataObject.channels) ? dataObject.channels.filter(
      (c) => !!c && typeof c === "object" && "name" in c && typeof c.name === "string"
    ).map((c) => c.name) : [];
    window.gpt_grapeshots = channels.length ? channels : ["no_category"];
    window.gpt_grapeshots_fr = dataObject.fr;
    const meta = document.createElement("meta");
    meta.id = "grapeshot";
    meta.name = "grapeshot_tag";
    meta.content = channels.join(",");
    document.head.appendChild(meta);
  });
})()`}
        </script>
      </Helmet>
    );
  }

  if (!grapeshots) {
    return;
  }
  return (
    <Helmet>
      <meta
        id="grapeshot"
        name="grapeshot_tag"
        content={grapeshots.channels.join(",")}
      />
      <script type="text/javascript">
        {`window.gpt_grapeshots = ${JSON.stringify(grapeshots.channels)};window.gpt_grapeshots_fr = ${JSON.stringify(grapeshots.fr)};`}
      </script>
    </Helmet>
  );
}
